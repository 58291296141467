import { useState, useEffect, type FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import DOMPurify from 'dompurify';

import { 
    fetchPropertyById, 
    updateProperty, 
    deleteImage, 
    updateImagesOrder, 
    uploadImage, 
    updateImages, 
    saveVideos, 
    deleteFloorplan, 
    updateFloorplan, 
    uploadFloorplan, 
    changeFloorplan, 
    deleteProperty 
} from "service/property.service";

import { toggleSnackbar } from "store/ui/ui-slice";
import { useAppDispatch } from "store/hooks";
import { waitFor } from "utils";

import { Image, Video } from "types/shared";

const useProperty = (propertyId: string | undefined, refreshProperty: (tab: string) => void) => {
    const [propertyIsLoading, setPropertyIsLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [property, setProperty] = useState<any>(null);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleToggleChange = (e: FormEvent<HTMLInputElement>) => {
        const { id } = e.target as HTMLInputElement;

        setProperty((prevState: any) => {
            return {
                ...prevState,
                [id]: !prevState[id]
            }
        })
    }
    
    const getProperty = async () => {
        if (!propertyId) return;

        try {
            const response = await fetchPropertyById(propertyId);

            if (response && response.status === 200) {
                const cleanDescription = DOMPurify.sanitize(response.data.property.description);
                setProperty({ ...response.data.property, description: cleanDescription });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleEdit = async (formData: any) => {
        if (!propertyId || !property) return;
        setIsLoading(true);

        try {
            const response = await updateProperty({ ...formData, isActive: property.isActive }, propertyId);

            if (response && response.status === 200) {
                await getProperty();
                await waitFor(100);
            
                refreshProperty('general');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteProperty = async () => {
        if (!propertyId) return;
        setPropertyIsLoading(true);

        try {
            const response = await deleteProperty(propertyId);

            if (response && response.status === 200) {
                dispatch(toggleSnackbar({ type: 'success', text: response.data.message }));
                navigate('/dashboard/properties/list');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setPropertyIsLoading(false);
        }
    };

    const handleDeleteImage = async (id: string) => {
        if (!propertyId) return;
        setPropertyIsLoading(true);

        try {
            const response = await deleteImage(propertyId, id);
            if (response && response.status === 200) await getProperty();
        } catch (error) {
            console.error(error);
        } finally {
            setPropertyIsLoading(false);
        }
    };

    const handleUpdateImagesOrder = async (images: Image[]) => {
        if (!propertyId) return;
        setPropertyIsLoading(true);

        try {
            const response = await updateImagesOrder(propertyId, images);
            if (response && response.status === 200) await getProperty();
        } catch (error) {
            console.error(error);
        } finally {
            setPropertyIsLoading(false);
        }
    };

    const handleSaveGallery = async (data: any) => {
        if (!data || !property) return;
        setPropertyIsLoading(true);

        try {
            const formData = new FormData();

            for (const item of data) {
                formData.append('images', item);
            }

            const response = await uploadImage(formData, property.alias);

            if (response && response.status === 200) {
                const imageResponse = await updateImages(property._id.toString(), response.data.images);
                if (imageResponse && imageResponse.status === 200) await getProperty();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setPropertyIsLoading(false);
        }
    };

    const handleUpdateVideos = async (videos: Video[]) => {
        if (!property) return;
        setPropertyIsLoading(true);

        try {
            const response = await saveVideos(property._id.toString(), videos);
            
            if (response && response.status === 200) {
                await getProperty();
                dispatch(toggleSnackbar({ type: 'success', text: response.data.message }));
            }
        } catch (err) {
            console.error(err);
        } finally {
            setPropertyIsLoading(false);
        }
    };

    const handleUpdateFloorplan = async (floorplan: File) => {
        if (!property) return;
        setPropertyIsLoading(true);
        let floorplanResponse;

        const floorplanData = new FormData();
        floorplanData.append('image', floorplan);

        try {
            const uploadResponse = await uploadFloorplan(floorplanData, property.alias);
            
            if (uploadResponse && uploadResponse.status === 200) {
                const configObject = {
                    key: uploadResponse.data.key,
                    name: uploadResponse.data.name,
                    url: uploadResponse.data.url
                };

                if (property.floorplan && property.floorplan.url) {
                    floorplanResponse = await changeFloorplan(property._id.toString(), configObject);
                } else {
                    floorplanResponse = await updateFloorplan(property._id.toString(), configObject);
                }

                if (floorplanResponse && floorplanResponse.status === 200) await getProperty();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setPropertyIsLoading(false);
        }
    };

    const handleDeleteFloorplan = async () => {
        if (!property) return;
        setPropertyIsLoading(true);

        try {
            const response = await deleteFloorplan(property._id.toString());
            if (response && response.status === 200) await getProperty();
        } catch (error) {
            console.error(error);
        } finally {
            setPropertyIsLoading(false);
        }
    };

    const isAvailable = () => Boolean(property?.isActive && !property?.isDeleted);

    useEffect(() => {
        getProperty();
    }, []);

    return {
        property,
        isAvailable,
        isLoading,
        propertyIsLoading,
        getProperty,
        handleEdit,
        handleDeleteProperty,
        handleDeleteImage,
        handleUpdateImagesOrder,
        handleSaveGallery,
        handleUpdateVideos,
        handleUpdateFloorplan,
        handleDeleteFloorplan,
        handleToggleChange
    };
};

export default useProperty;