import React, { useState, useEffect, type ReactNode } from 'react';

import { fetchGlobalSettings } from 'service/global.service';

import { Loader } from 'components/shared';

type ContextTypes = {
    globalSettings: any
}

type CurrentUserContextTypes = {
    value?: ContextTypes;
    children: ReactNode;
}

export const GlobalSettingsContext = React.createContext<ContextTypes>({
    globalSettings: null
});

export const GlobalSettingsProvider = (props: CurrentUserContextTypes) => {
    const [globalSettings, setGlobalSettings] = useState<any>(null);

    const fetchSettings = async () => {
        try {
            const response = await fetchGlobalSettings();
            
            if (response && response.status === 200) {
                setGlobalSettings(response.data.settings);
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    useEffect(() => { 
        fetchSettings();
    }, []);

    const value = { 
        globalSettings
    };

    return (
        <GlobalSettingsContext.Provider value={ value }>
            { globalSettings ? props.children : <Loader /> }
        </GlobalSettingsContext.Provider>
    )
}

export const useGlobalSettings = () => React.useContext(GlobalSettingsContext);