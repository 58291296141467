import { api } from "./dataService";
import { HTTP_METHODS } from 'constant';

import { FiltersDataType } from "types/shared";

// FETCH PROPERTY BY ID

export const fetchPropertyById = async (propertyId: string) => {
    const res = await api(`/api/v1/listings/${propertyId}`);

    return res;
}

export const createProperty = async (formData: any) => {
    const res = await api('/api/v1/listings/create', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({ formData: formData })
    })

    return res;
}

// DELETE PROPERTY

export const deleteProperty = async (propertyId: string) => {
    const res = await api('/api/v1/listings/delete', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({ propertyId: propertyId })
    })

    return res;
}

// UPDATE PROPERTY

export const updateProperty = async (formData: any, propertyId: string) => {
    const res = await api(`/api/v1/listings/${propertyId}`, {
        method: HTTP_METHODS.PATCH,
        data: JSON.stringify({ formData: formData })
    })

    return res;
}

// DUPLICATE PROPERTY

export const duplicateProperty = async (propertyId: string) => {
    const res = await api('/api/v1/listings/duplicate', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({ propertyId: propertyId })
    })

    return res;
}

// PROPERTY IMAGES

export const uploadImage = async (formData: FormData, alias?: string) => {
    const res = await api(`/api/v1/listings/upload-image?alias=${alias}`, {
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })

    return res;
}

export const updateImages = async (propertyId: string, images: any) => {
    const res = await api('/api/v1/listings/update-images', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({
            propertyId: propertyId,
            images: images
        })
    })

    return res;
}

// DELETE FLOORPLAN

export const deleteFloorplan = async (propertyId: string) => {
    const res = await api(`/api/v1/listings/delete-floorplan`, {
        method: 'POST',
        data: JSON.stringify({ propertyId })
    })

    return res;
}

// PROPERTY FLOORPLAN

export const uploadFloorplan = async (formData: FormData, alias: string) => {
    const res = await api(`/api/v1/listings/upload-floorplan?alias=${alias}`, {
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })

    return res;
}

export const updateFloorplan = async (propertyId: string, floorplan: { key: string; name: string; url: string; }) => {
    const res = await api('/api/v1/listings/update-floorplan', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({
            propertyId: propertyId,
            floorplan: floorplan
        })
    })

    return res;
}

// CHANGE FLOORPLAN

export const changeFloorplan = async (propertyId: string, floorplan: { key: string; name: string; url: string; }) => {
    const res = await api('/api/v1/listings/change-floorplan', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({
            propertyId: propertyId,
            floorplan: floorplan
        })
    })

    return res;
}

// UPDATE VIDEOS

export const updateVideos = async (videos: {
    name: string; url: string; type: string;
}[], propertyId: string) => {

    const res = await api(`/api/v1/listings/update-videos`, {
        method: 'POST',
        data: JSON.stringify({ videos, propertyId })
    })

    return res;
}

export const fetchListings = async (config: { skip: number; limit: number; }) => {
    const res = await api(`/api/v1/listings/fetch`, {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({ 
            skip: config.skip,
            limit: config.limit
         })
    });

    return res; 
}


// FIND NEARBY LCOATIONS

export const findNearbyLocations = async (query: string, config: { skip: number; limit: number; }) => {
    const res = await api(`/api/v1/listings/nearby-search${query}`, {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({
            config: config
        })
    })

    return res;
}

// DELTE IMAGES

export const deleteImage = async (propertyId: string, imageId: string) => {
    const res = await api('/api/v1/listings/delete-image', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({
            propertyId: propertyId,
            imageId: imageId
        })
    })

    return res;
}

// UPDATE IMAGES ORDER

export const updateImagesOrder = async (propertyId: string, images: any) => {
    const res = await api('/api/v1/listings/update-images-order', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({
            propertyId: propertyId,
            images: images
        })
    })

    return res;
}


// SAVE VIDEOS

export const saveVideos = async (propertyId: string, videos: any) => {
    const res = await api('/api/v1/listings/save-videos', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify({
            propertyId: propertyId,
            videos: videos
        })
    })

    return res;
}