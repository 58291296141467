import { useState } from 'react';

import { createExperiment, deleteExperiment } from 'service/user.service';

const useExperiments = (accountId: string, onFetchCurrentAccount: () => void) => {
    const [accountIsLoading, setAccountIsLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleSubmitExperiment = async (data: { name: string; isAvailable: boolean; }) => {
        if (!accountId) return;
        setAccountIsLoading(true);

        try {
            const response = await createExperiment(accountId, data);

            if (response && response.status === 201) {
                await onFetchCurrentAccount();
                setIsOpen(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setAccountIsLoading(false);
        }
    }

    const handleDeleteExperiment = async (experimentId: string) => {
        if (!accountId) return;
        setAccountIsLoading(true);

        try {
            const response = await deleteExperiment(accountId, experimentId);

            if (response && response.status === 200) {
                await onFetchCurrentAccount();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setAccountIsLoading(false);
        }
    }

    return {
        isOpen,
        setIsOpen,
        accountIsLoading,
        handleSubmitExperiment,
        handleDeleteExperiment
    }
}

export default useExperiments;