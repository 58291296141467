import { Suspense, type FC, lazy } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { SignIn, PrivateRoute, ExternalRedirect } from "views/auth";
import Property from "views/account/properties/public/Property";

import { ErrorFallback, Loader, Snackbar } from "components/shared";
import { ErrorBoundary } from "react-error-boundary";

import { myAccountRoutes } from "routes";

import { ModalRenderer } from "components/modals/core/ModalRenderer";
 
const Error: FC = lazy(() => import('views/shared/Error'));
const RecoverPassword: FC = lazy(() => import('views/auth/RecoverPassword'));
const NewPassword: FC = lazy(() => import('views/auth/NewPassword'));

const App: FC = () => {
  const fallbackRender = ({error}: {error: Error}) => {
    console.error(error);
    return <ErrorFallback />;
  }

  return (
    <ErrorBoundary fallbackRender={ fallbackRender }>
      <BrowserRouter>
        <Snackbar />
        <ModalRenderer />
        <Suspense fallback={ <Loader /> }>
          <Routes>
            <Route path="/" element={ <ExternalRedirect /> } />
            <Route path="/signin" element={ <SignIn /> } />
            <Route path="/public/property/:propertyId" element={ <Property /> } />
            <Route path="/recover-password" element={ <RecoverPassword /> } />
            <Route path="/new-password/:resetToken" element={ <NewPassword /> } />
            <Route path="/error" element={ <Error /> } />
            <Route path="/dashboard" element={ <PrivateRoute /> }>
              { myAccountRoutes.map((route: any, index: number) => (
                  <Route 
                    key={ index } 
                    index={ route.index }
                    path={ typeof route.path === 'function' ? route.path() : route.path } 
                    element={ <route.component /> } 
                  />
                )) }
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default App;