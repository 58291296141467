import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { GlobalSettingsProvider } from 'context/SettingsContext';
import { AuthContextProvider } from 'context/AuthContext';

import { Provider } from 'react-redux';
import { store } from 'store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GlobalSettingsProvider>
    <AuthContextProvider>
      <Provider store={ store }>
          <App />
      </Provider>
    </AuthContextProvider>
  </GlobalSettingsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
