import { useState, useEffect } from "react";
import { fetchProperty } from "service/public.service";

import DOMPurify from "dompurify";

const usePublicProperty = (propertyId: string | undefined) => {
    const [property, setProperty] = useState<any>(null);
    
    useEffect(() => {
      const fetchPropertyById = async () => {
        if (!propertyId) return;
  
        try {
          const response = await fetchProperty(propertyId);
          
          if (response && response.status === 200) {
            setProperty({
              ...response.data.property,
              description: DOMPurify.sanitize(response.data.property.description),
            });
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchPropertyById();
    }, [propertyId]);
  
    const isAvailable = () =>
      Boolean(property?.isActive && !property?.isDeleted);
  
    return { property, isAvailable };
};

export default usePublicProperty;