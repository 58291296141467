import { lazy, type LazyExoticComponent } from 'react';

export enum Modals {
    CreateAccountModal,
    DeleteAccountModal
}

export const modalsConfig: Record<Modals, LazyExoticComponent<any>> = {
    [Modals.CreateAccountModal]: lazy(() => import('../CreateAccountModal')),
    [Modals.DeleteAccountModal]: lazy(() => import('../DeleteAccountModal'))
}